#About{
    color: rgb(18, 11, 43);
    background-color: rgb(241, 242, 255);
    height:fit-content;
    padding-bottom: 200px;
}
.gap {
    height: 65px;
    width: 100%;
    color: white;
    background-color: rgb(255, 255, 255);
}
.head{
    position: relative;
    left: 43%;
    margin-top: 12px;
    text-align: center;
    width: 160px;
    font-weight: 650;
    font-size: 26px;
    background-color: rgba(35, 18, 76, 0);
    border-bottom: 2px solid rgb(6, 6, 35);
    color: rgb(19, 13, 44);
    padding: 3px 4px;
    border-radius: 8px;
}
.aboutme{
    padding: 10px;
    padding-left: 20px ;
    text-align: left;
    max-width: 45%;
    font-size: 20px;
    position: relative;
}
.aboutme img{
    height: 300px;
}
.aboutcontainer{
    display: flex;
    padding: 10px;
}
.skills{
    margin-left: 40px;
    display: inline-block;
    text-align: left;
    padding:0px 10px 10px 10px;
}
.skills-head {
    display: flex;
    flex-wrap: wrap;
}
.skills-head span{
    font-size: 18px;
    background-color: rgb(36, 27, 67);
    color: rgb(255, 255, 255);
    height: 26px;
    text-align: center;
    padding: 4px 5px;
    border-radius: 3px;
    min-width: 100px;
    transition: 0.4s;
    border: 1.5px solid rgba(10, 10, 37, 0);
    border-right: 2px solid rgba(10, 10, 37, 0);
}
.skills-head span:hover{
    border: 1.5px solid rgb(10, 10, 37);
    color: rgb(11, 11, 49);
    
    background-color: rgba(226, 220, 245, 0);
}
.skill-lau{
    position: relative;
    left: 5%;
    top: -8px;
    margin: 7px;
    border: 1px solid rgb(21, 12, 52);
    background-color: rgba(0, 0, 0, 0);
    color: rgb(20, 13, 47);
    padding: 7px;
    border-radius: 5px;
    transition: 0.4s;
}
.skill-lau:hover{
    border: 1px solid rgba(21, 12, 52, 0);

}

@media only screen and (max-width: 600px) {
    
    #About{
        padding-bottom: 100px;
    }
    .head{
        position: relative;
        left: 30%;
        margin-top: 20px;
        text-align: center;
        width: 150px;
        font-weight: 700;
        font-size: 26px;
        background-color: rgba(202, 202, 202, 0);
        color: rgb(15, 13, 42);
        padding: 7px 1px;
        border-radius: 5px;
    }
    .aboutme{
        padding: 10px;
        text-align: center;
        max-width: 100%;
        font-size: 16px;
    }
    .aboutcontainer{
        padding: 15px;
        display: inline-block;
        justify-content: space-between;
    }
    .skills{
        margin-left: 0px;
    }
    .skills-head span{
        font-size: 16px;
        border-radius: 5px;
        height: 21px;
        min-width:  86px;
    }
    .skill-lau{
        padding: 5px;
        font-size: 10px ;
        margin: 5px;
    }
    .intern{
        font-size: 0px;
        
    }
}
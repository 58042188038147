#Contact {
  background-color: rgb(255, 255, 255);
  height: fit-content;
  margin-bottom: 30px;
}

.gap2 {
  font-size: 0;
  height: 63px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  margin-bottom: 20px;
}

#head3 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 22px;
  position: relative;
  display: inline-block;
  top: -2%;
  left: 1%;
  background-color: rgba(6, 5, 26, 0);
  color: rgb(18, 14, 53);
  padding: 6px 10px;
  border-left: 1px solid rgb(8, 8, 41);
  border-bottom: 1px solid rgb(8, 8, 41);
  border-radius: 4px;
  margin: 10px;
}

#head3::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 56%;
  left: 100%;
  height: 3px;
  background-color: rgb(0, 0, 0);
  width: 70%;
  transform: translateY(-50%);
}

.contact-form {
  position: relative;
  left: 27%;
  padding: 35px;
  width: 45%;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0);
  height: auto;
  border-radius: 5px;
  box-sizing: border-box;
}

.contact-form h2 {
  text-align: center;
  color: rgb(55, 55, 75);
  margin-top: -10px;
  font-size: 20px;
  font-weight: 500;
}

.name-email {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.contactinput {
  color: rgb(17, 11, 44);
  flex: 1;
  padding: 10px;
  border: 1px solid rgba(28, 16, 57, 0.408);
  border-radius: 5px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  outline: none;
}

.contact-input {
  color: rgb(28, 16, 57);
  width: 100%;
  height: 150px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid rgba(28, 16, 57, 0.408);
  border-radius: 5px; 
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  resize: none; 
}
::placeholder{
  color: rgb(204, 204, 204);
}

#input-subject {
  margin-bottom: 15px;
}
textarea:focus{
  outline: none;
}
.contact-button {
  padding: 8px 18px;
  border: none;
  border-radius: 5px;
  background-color: rgba(55, 55, 75, 0);
  color: rgb(17, 12, 48);
  cursor: pointer;
  width: 120px;
  font-size: 15px;
  border: 1.5px solid rgba(13, 8, 35, 0.415);
  transition: 0.2s;
}

.contact-button:hover {
  background-color: rgba(23, 23, 63, 0.174);
}

@media only screen and (max-width: 600px) {
  #head3 {
    margin-left: 3%;
    font-size: 20px;
    font-weight: 700;
  }

  .contact-form {
    left: 0;
    width: 90%;
    margin: 0 auto;
  }

  .name-email {
    flex-direction: column;
  }

  .contact-button {
    width: 40%;
  }
}

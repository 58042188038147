/* Global Styles */
#Project {
  height: fit-content;
  background: radial-gradient(circle, #9b62f7bc, #8396d5c2 37%, #00d4ff00 80%);
}

.gap2 {
  font-size: 0;
  height: 63px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  margin-bottom: 20px;
}

#head2 {
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 22px;
  position: relative;
  display: inline-block;
  top: -2%;
  left: 1%; 
  background-color: rgba(228, 228, 228, 0);
  color: rgb(4, 3, 18);
  padding: 6px 13px;
  border-radius: 4px;
  border-left: 1px solid rgb(14, 14, 48);
  border-bottom: 1px solid rgb(14, 14, 48);
  margin: 10px;
}

#head2::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 56%;
  left: 100%;
  height: 3px;
  background-color: black;
  width: 70%;
  transform: translateY(-50%);
}

.project-containers {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  justify-content: center;
  padding: 100px;
}

.container {
  background-color: rgba(255, 255, 255, 0.684);
  height: 520px;
  padding: 20px;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%; 
  max-width: 600px;
  color: rgb(7, 7, 36);
  border: 1px solid rgba(10, 10, 49, 0);
  transition: 0.6s;
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  
}
.container:hover{
  transform: scale(1.04);
}
.project-video-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.container video {
  position: relative;
  width: 80%;
  border-radius: 10px;
  border: 3px solid rgb(255, 255, 255);
  box-shadow: 1px 0px 10px rgba(49, 49, 49, 0.136);
  transition: 0.3s;
}

.project-title {
  font-size: 24px;
  font-family: "Noto Sans JP", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  margin-top: 15px;
}

.project-desc {
  font-size: 15px;
  margin-top: 10px;
}

.project-tag {
  font-size: 18px;
  color: rgb(90, 90, 90);
  word-spacing: 15px;
  margin-top: 10px;
}
.project-bottom{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding: 0px 20px;
}
.project-website {
  font-size: 14px;
  display: inline-block;
  margin-top: -5px;
  text-decoration: none;
  color: rgb(254, 254, 254);
  padding: 7px 12px;
  border-radius: 4px;
  transition: 0.3s;
  border: 0.5px solid rgba(40, 40, 40, 0.336);
  background-color: rgb(23, 23, 92);
}

.project-website:hover {
  border: 0.5px solid rgba(128, 128, 128, 0);
  background-color: rgba(11, 11, 42, 0.148);
}

.gitopen {
  position: relative;
  top: -50%;
  left: calc(100% - 40px);
  font-size: 30px;
  color: rgb(26, 18, 67);
  transition: 0.2s;
}

.gitopen:hover {
  color: rgb(70, 70, 70);
}

@media only screen and (max-width: 600px) {

  #head2 {
    margin-left: 3%;
    font-size: 20px;
    font-weight: 700;
  }

.project-containers {
    padding: 50px;
  }

  .container {
    height: fit-content;
    width: 100%;
    margin: 25px 0px auto;
    padding: 10px 15px;
  }

  .container video {
    width: 80%;
    left: 0;
    border-radius: 10px;
    border: 3px solid rgb(255, 255, 255);
    box-shadow: 1px 0px 10px rgba(49, 49, 49, 0.136);
    transition: 0.3s;
  }

  .project-title {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.745);
    font-weight: 800;
    font-style: normal;
    margin-top: 10px;
  }

  .project-desc {
    font-size: 15px;
    margin-top: 10px;
  }

  .project-tag {
    font-size: 18px;
    color: rgb(90, 90, 90);
    word-spacing: 15px;
    margin-top: 10px;
  }

  

  
}

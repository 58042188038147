#End{
    height: fit-content;
}
.end-container{
    background-color: rgb(240, 239, 255);
    color: rgb(18, 19, 62);
    padding: 10px;
    padding-bottom:20px ;
}
.adi-name{
    font-size: 30px;
    font-family: "Noto Sans JP", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}
.cont2 a{
    color: rgb(18, 19, 62);
    margin: 15px;
    margin-left: 0px;
    text-decoration: none;
    padding: 10px;
    transition: 0.3s;
    border-radius: 35px;
    border: 1px solid rgba(18, 19, 62, 0);

}
.cont2 a:hover{
    transition: 0.3s;
    border: 1px solid rgb(18, 19, 62);
}

@media only screen and (max-width: 600px) {
    .cont2{
        display: flex;
        flex-direction: column;
    }
    .cont2 a{
        margin: 5px 0px;
        width: 63%;
    }
}